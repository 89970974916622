import cs from "classnames"
import moduleCss from "./index.module.scss";

import { Modal, Button, Timeline, } from 'antd';

export default function Part(props) {
    const showModal = () => {
        props.setIsModalOpen(true);
    };

    const handleOk = () => {
        props.setIsModalOpen(false);
    };

    const handleCancel = () => {
        props.setIsModalOpen(false);
    };


    const detailCostData = [
        {title: 'Unit price', value: '$65',},
        {title: 'Number', value: '120',},
        {title: 'Tax refund rate', value: '12%',},
        {title: 'Customs clearance tax rate', value: '4%',},
        {title: 'Anti dumping tax rate', value: '0',},
        {title: 'Single piece cost', value: '$73.64',},
        {title: 'Overall cost', value: '$8,836.80',},
    ]

    const detailCostData2 = [
        {title: 'Unit price', value: '$90',},
        {title: 'Number', value: '90',},
        {title: 'Tax refund rate', value: '9%',},
        {title: 'Customs clearance tax rate', value: '3%',},
        {title: 'Anti dumping tax rate', value: '2%',},
        {title: 'Single piece cost', value: '$103.30',},
        {title: 'Overall cost', value: '$9,297',},
    ]
    return (<>
        <Modal title="Detailed cost"
            className={moduleCss.detailcostmodal}
            footer={null}
            open={props.isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}>
            <div className={moduleCss.detailCost}>
                <div className="top">
                    {/* <div className="title">Detailed cost </div> */}
                    {/* <div className="row">
                        <div className="title">GUANGZHOU</div>
                        <div className="arrow"></div>
                        <div className="title">ISTANBUL</div>
                        <div className="arrow"></div>
                        <div className="title">Malaysia</div>
                    </div> */}
                    <div className="titlep">Product 1</div>
                    <div className="pro">
                        {detailCostData.map((e, i) => {
                                return (<div key={i} className="cell">
                                    <div className="t1">{ e.title}</div>
                                    <div className="t2">{ e.value}</div>
                                </div>)
                            })}
                    </div>
                    <div className="titlep">Product 2</div>
                    <div className="pro">
                        {detailCostData2.map((e, i) => {
                            return (<div key={i} className="cell">
                                <div className="t1">{ e.title}</div>
                                <div className="t2">{ e.value}</div>
                            </div>)
                        })}
                    </div>
                </div>
                <div className="down">
                <div className="p1">
                        <div className="a1">Single piece cost</div>
                        <div className="a2">$176.94</div>
                    </div>
                    <div className="p2">
                        <div className="a1">total cost</div>
                        <div className="a2">$15,428.57</div>
                    </div>
                </div>
            </div>
      </Modal>
    </>)
}