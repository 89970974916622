
import cs from "classnames"
import { useState, useEffect, useRef, useCallback, } from "react";

import Head from "./../header"
import Foot from "./../footer"

import moduleCss from "./index.module.scss";

import phone_png from "@/assets/img/about/phone.png";
import phone_gray_png from "@/assets/img/about/phone_gray.png";
import email_gray_png from "@/assets/img/about/email_gray.png";
import email_png from "@/assets/img/about/email.png";
import loc_gray_png from "@/assets/img/about/loc_gray.png";
import loc_png from "@/assets/img/about/loc_.png";

export default function About() {
    const banner = {
        title: 'About Us',
        desc: `Dingyi Supply chain is a young and dynamic companyfounded in 2018 and headquartered in Shenzhen, China.we have an experienced team, and core members with more than 26 yearsof experience in shipping.
    services covering sea freight, trucking, customs clearance, insurance,overseas warehousing, and destination services.
    On our platform, customers can easily search rates, make inquiry,searchshipping schedule.
    make bookings, fulfill order, and tracking cargo,
    Our headquartered in shenzhen, we have set up branches and offices in Guangzhou,Jiangmen, Shanghai,Qingdao, Turkey branches will be establishin April， 2024`}
    
    const wedesc = {
        title: 'We have partnered with',
    }

    const concat_desc = { title: 'Contact Us' }

    const [activeIndex, setActiveIndex] = useState(1)
    const [activeBranchIndex, setActiveBranchIndex] = useState(1)

    const enterItem = useCallback((index) => { 
        setActiveIndex(index)
    }, [])
    
    return (
        <>
            <Head/>
            <div className={moduleCss.banner}>
                <p>{banner.title}</p>
                <div>{ banner.desc}</div>
            </div>
            <div className={moduleCss.wedesc}>
                <p>{wedesc.title}</p>
                <div className="bottom">
                    <div className="item active">
                        <div className="num">100+</div>
                        <div className="tip">liner companies</div>
                    </div>
                    <div className="item">
                        <div className="num">200+</div>
                        <div className="tip">Cooperative Partners</div>
                    </div>
                    <div className="item">
                        <div className="num">100+</div>
                        <div className="tip">cities Covered</div>
                    </div>
                </div>
            </div>
            <div className={moduleCss.concat_desc}>
                <p>{concat_desc.title}</p>
                <div className="bottom">
                    <div className={cs('item', {'active': activeIndex == 1})} onMouseEnter={() => enterItem(1)}>
                        <img src={activeIndex == 1 ? phone_png : phone_gray_png}></img>

                        <div className="tip">+86 13534115387</div>
                    </div>
                    <div className={cs('item', {'active': activeIndex == 2})} onMouseEnter={() => enterItem(2)}>
                        <img src={activeIndex == 2 ? email_png : email_gray_png}></img>
                        <div className="tip">Hegel@dingyisz.com</div>
                    </div>
                    <div className={cs('item', {'active': activeIndex == 3})} onMouseEnter={() => enterItem(3)}>
                        <img src={activeIndex == 3 ? loc_png : loc_gray_png}></img>

                        <div className="tip">3rd Floor,Yuanzheng Science Park,Bantian Street,Longgang District,Shenzhen,China</div>
                    </div>
                </div>
            </div>
            <div className={moduleCss.branches}>
                <p>Offices & Branches</p>
                <div className="row">
                    <div className={cs('item', { 'active': activeBranchIndex == 1 })} style={{marginRight: '40px'}} onMouseEnter={() => setActiveBranchIndex(1)}>
                        <div className="icon">Office</div>
                        <div className="tip">Guangzhou</div>
                        <div className="tip">Address: room 211 Building A, Haohui CreativePark,Dashi St, Panyu, Guangzhou</div>
                        <div className="tip2">
                            <span>Contact</span>
                            <span className="line"></span>
                            <span className="name">Hegel</span>
                            <span className="line"></span>
                            <span>phone</span>
                            <span className="line"></span>
                            <span className="name">+86 208 4827296</span>
                        </div>
                    </div>
                    <div className={cs('item', {'active': activeBranchIndex == 2})} onMouseEnter={() => setActiveBranchIndex(2)}>
                        <div className="icon">DINGYI SUPPLY CHAIN(TURKEY) LTD.</div>
                        <div className="tip">Turkey</div>
                        <div className="tip">Address: Atatürk mah. Gardenya Plaza-3 No:20 iç kapı no:24 Ataşehir/İstanbul</div>
                        <div className="tip2">
                            <span>Contact</span>
                            <span className="line"></span>
                            <span className="name">Omer Bozkurt</span>
                            <span className="line"></span>
                            <span>phone</span>
                            <span className="line"></span>
                            <span className="name">+90 053 07296601</span>
                        </div>
                    </div>
               </div>
            </div>
            <Foot/>
        </>
    )
}

