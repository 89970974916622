import PCddp from './pc/inquiry/ddp';
import PC from './pc/inquiry';
// import Mobile from './h5/product';

import {useParams} from "react-router-dom";

export default function Inquiry(props) { 
    let flag = window.navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|mbian|Windows Phone)/i
    );
    let { type } = useParams();
    const ddps = ['DDP', 'DDU',]
    if (flag) {
        return ddps.includes(type) ? <PCddp key={new Date().getTime()} /> : <PC key={ new Date().getTime()} />
    } else {
        return ddps.includes(type) ? <PCddp key={new Date().getTime()} /> : <PC key={ new Date().getTime()} />
    }
}