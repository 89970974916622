import { ConfigProvider } from "antd";
import { Suspense, lazy } from "react";
import { createRoot } from "react-dom/client";

const ModalProductDetail = lazy(() => import("./ModalProductDetail"));

export default function openProductDetailModal(data) {
  return new Promise((resolve, reject) => {
    let modalMount = document.querySelector("#modal-mount");
    if (modalMount === null) {
      modalMount = document.createElement("div");
      modalMount.id = "modal-mount";
      document.body.appendChild(modalMount);
    }
    const domRoot = createRoot(modalMount);

    const succCallback = (data) => {
      resolve(data);
      domRoot.unmount();
    };
    const errorCallback = (data) => {
      // reject(data);
      domRoot.unmount();

    };
    const config = {
      token: {
        colorPrimary: "#FF954D",
      },
    };
    domRoot.render(
      <ConfigProvider theme={config}>
        <Suspense fallback={null}>
          <ModalProductDetail succCallback={succCallback} errorCallback={errorCallback} data={data} />
        </Suspense>
      </ConfigProvider>
    );
  });
}
