import cs from "classnames"
import moduleCss from "./index.module.scss";

import { Modal, Button, Timeline, Table} from 'antd';

export default function Part(props) {
    const showModal = () => {
        props.setIsModalOpen(true);
    };

    const handleOk = () => {
        props.setIsModalOpen(false);
    };

    const handleCancel = () => {
        props.setIsModalOpen(false);
    };


    const columns = [
        {
          title: 'Cost Name',
          dataIndex: 'Cost Name',
            key: 'Cost Name',
        //   width: 120,
          render: (text) => <div className="tabletext">{text}</div>,
        },
        {
          title: 'Unit Price',
          dataIndex: 'Unit Price',
            key: 'Unit Price',
            // width: 150,
            render: (text) => <div className="tabletext">{text}</div>,

        },
        {
          title: 'Quantity',
            dataIndex: 'Quantity',
        //   width: 150,
            key: 'Quantity',
            render: (text) => <div className="tabletext">{text}</div>,

        },
        {
          title: 'Unit',
          key: 'Unit',
            dataIndex: 'Unit',
        //   width: 150,
          render: (text) => <div className="tabletext">{text}</div>,

        },
        {
          title: 'Cost',
          key: 'Cost',
            dataIndex: 'Cost',
        //   width: 150,
          render: (text) => <div className="tabletext bold">{text}</div>,

        },
      ];
    
  const data2 = [
        {
          'Cost Name': 'EMS',
          'Unit Price': '$25',
          'Quantity': '12',
          'Unit': 'parcel',
          'Cost': '$300',
          },
          {
            'Cost Name': 'VGM',
            'Unit Price': '$10',
            'Quantity': '3',
            'Unit': 'check',
            'Cost': '$30',
          },
        
          {
            'Cost Name': 'Manifest Fee',
            'Unit Price': '$60',
            'Quantity': '1',
            'Unit': 'manifest',
            'Cost': '$60',
          },
  ];
  
  const data1 = [
    {
      'Cost Name': 'THC',
      'Unit Price': '$150',
      'Quantity': '4',
      'Unit': 'TEU',
      'Cost': '$600',
      },
      {
        'Cost Name': 'Visa fees',
        'Unit Price': '$40',
        'Quantity': '5',
        'Unit': 'set',
        'Cost': '$200',
      },
    
      {
        'Cost Name': 'Booking fee',
        'Unit Price': '$80',
        'Quantity': '1',
        'Unit': 'booking',
        'Cost': '$80',
      },
    
      {
        'Cost Name': 'EIR',
        'Unit Price': '$35',
        'Quantity': '8',
        'Unit': 'piece',
        'Cost': '$280',
      },
];
    
    const footerNode = (<div className="footer">
        <span>total</span>
        <span>CNY 3600</span>
    </div>)
    return (<>
        <Modal title="Potential costs at the port of departure"
            className={moduleCss.detailcostmodal}
            footer={null}
            open={props.isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}>
            <div className={moduleCss.detailCost}>
                <div className="top">
                    {/* <div className="titlep">Potential costs at the port of departure</div> */}
                    <div className="pro">
                        <Table style={{ width: '600px' }} columns={columns}
                            dataSource={data1}
                            pagination={null}
                            footer={() => {return (<div className="footer">
                            <span>total</span>
                            <span>$ 1160</span>
                        </div>)}} />
                    </div>
                    <div className="titlep">Potential costs</div>
                    <div className="pro">
                    <Table style={{ width: '600px' }} columns={columns}
                            dataSource={data2}
                            pagination={null}
                            footer={() => {return (<div className="footer">
                            <span>total</span>
                            <span>$ 450</span>
                        </div>)}} />
                    </div>
                </div>
                <div className="down">
                    <div className="p1">
                        <div className="a2">Estimated total</div>
                    </div>
                    <div className="p2">
                        <div className="a1">$1,610</div>
                        <div className="a2">USD 1,610 &nbsp;&nbsp;  CNY 11,592</div>
                    </div>
                </div>
            </div>
      </Modal>
    </>)
}