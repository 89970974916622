export const goodsTypeOptions = [
    {
        "label": "普货(General Cargo)",
        "value": 10205
    },
    {
        "label": "一般化工品(General Chemicals)",
        "value": 10206
    },
    {
        "label": "危险品(Dangerous goods)",
        "value": 10207
    },
    {
        "label": "冷冻冷藏(Refrigerated)",
        "value": 10208
    },
    {
        "label": "特种柜及大件货(Special Equipment and Bulk Goods)",
        "value": 10209
    },
    {
        "label": "冷危(Refrigerated Dangerous Goods)",
        "value": 10210
    },
    {
        "label": "私人物品(Personal Effects)",
        "value": 10211
    },
    {
        "label": "贵重物品(Valuables)",
        "value": 10212
    },
    {
        "label": "超重超长货物(Overweight&Overlength Cargo)",
        "value": 17521
    },
    {
        "label": "挂衣(Hanging Clothes)",
        "value": 17522
    },
    {
        "label": "药品(Drugs)",
        "value": 17523
    },
    {
        "label": "易腐生鲜(Perishable And Fresh)",
        "value": 17524
    },
    {
        "label": "整车(Vehicle)",
        "value": 17525
    },
    {
        "label": "电子产品(Electronic Products)",
        "value": 17526
    }
]

export const countryOptions = [
    {
        "countyId": "1",
        "countyCode": "AFG",
        "countyName": "Afghanistan (AFG, Asia)"
    },
    {
        "countyId": "2",
        "countyCode": "ALA",
        "countyName": "Aland Islands (ALA, Europe)"
    },
    {
        "countyId": "3",
        "countyCode": "ALB",
        "countyName": "Albania (ALB, Europe)"
    },
    {
        "countyId": "4",
        "countyCode": "DZA",
        "countyName": "Algeria (DZA, Africa)"
    },
    {
        "countyId": "5",
        "countyCode": "ASM",
        "countyName": "American Samoa (ASM, Oceania)"
    },
    {
        "countyId": "6",
        "countyCode": "AND",
        "countyName": "Andorra (AND, Europe)"
    },
    {
        "countyId": "7",
        "countyCode": "AGO",
        "countyName": "Angola (AGO, Africa)"
    },
    {
        "countyId": "8",
        "countyCode": "AIA",
        "countyName": "Anguilla (AIA, Americas)"
    },
    {
        "countyId": "9",
        "countyCode": "ATA",
        "countyName": "Antarctica (ATA, Polar)"
    },
    {
        "countyId": "10",
        "countyCode": "ATG",
        "countyName": "Antigua And Barbuda (ATG, Americas)"
    },
    {
        "countyId": "11",
        "countyCode": "ARG",
        "countyName": "Argentina (ARG, Americas)"
    },
    {
        "countyId": "12",
        "countyCode": "ARM",
        "countyName": "Armenia (ARM, Asia)"
    },
    {
        "countyId": "13",
        "countyCode": "ABW",
        "countyName": "Aruba (ABW, Americas)"
    },
    {
        "countyId": "14",
        "countyCode": "AUS",
        "countyName": "Australia (AUS, Oceania)"
    },
    {
        "countyId": "15",
        "countyCode": "AUT",
        "countyName": "Austria (AUT, Europe)"
    },
    {
        "countyId": "16",
        "countyCode": "AZE",
        "countyName": "Azerbaijan (AZE, Asia)"
    },
    {
        "countyId": "18",
        "countyCode": "BHR",
        "countyName": "Bahrain (BHR, Asia)"
    },
    {
        "countyId": "19",
        "countyCode": "BGD",
        "countyName": "Bangladesh (BGD, Asia)"
    },
    {
        "countyId": "20",
        "countyCode": "BRB",
        "countyName": "Barbados (BRB, Americas)"
    },
    {
        "countyId": "21",
        "countyCode": "BLR",
        "countyName": "Belarus (BLR, Europe)"
    },
    {
        "countyId": "22",
        "countyCode": "BEL",
        "countyName": "Belgium (BEL, Europe)"
    },
    {
        "countyId": "23",
        "countyCode": "BLZ",
        "countyName": "Belize (BLZ, Americas)"
    },
    {
        "countyId": "24",
        "countyCode": "BEN",
        "countyName": "Benin (BEN, Africa)"
    },
    {
        "countyId": "25",
        "countyCode": "BMU",
        "countyName": "Bermuda (BMU, Americas)"
    },
    {
        "countyId": "26",
        "countyCode": "BTN",
        "countyName": "Bhutan (BTN, Asia)"
    },
    {
        "countyId": "27",
        "countyCode": "BOL",
        "countyName": "Bolivia (BOL, Americas)"
    },
    {
        "countyId": "155",
        "countyCode": "BES",
        "countyName": "Bonaire, Sint Eustatius and Saba (BES, Americas)"
    },
    {
        "countyId": "28",
        "countyCode": "BIH",
        "countyName": "Bosnia and Herzegovina (BIH, Europe)"
    },
    {
        "countyId": "29",
        "countyCode": "BWA",
        "countyName": "Botswana (BWA, Africa)"
    },
    {
        "countyId": "30",
        "countyCode": "BVT",
        "countyName": "Bouvet Island (BVT, )"
    },
    {
        "countyId": "31",
        "countyCode": "BRA",
        "countyName": "Brazil (BRA, Americas)"
    },
    {
        "countyId": "32",
        "countyCode": "IOT",
        "countyName": "British Indian Ocean Territory (IOT, Africa)"
    },
    {
        "countyId": "33",
        "countyCode": "BRN",
        "countyName": "Brunei (BRN, Asia)"
    },
    {
        "countyId": "34",
        "countyCode": "BGR",
        "countyName": "Bulgaria (BGR, Europe)"
    },
    {
        "countyId": "35",
        "countyCode": "BFA",
        "countyName": "Burkina Faso (BFA, Africa)"
    },
    {
        "countyId": "36",
        "countyCode": "BDI",
        "countyName": "Burundi (BDI, Africa)"
    },
    {
        "countyId": "37",
        "countyCode": "KHM",
        "countyName": "Cambodia (KHM, Asia)"
    },
    {
        "countyId": "38",
        "countyCode": "CMR",
        "countyName": "Cameroon (CMR, Africa)"
    },
    {
        "countyId": "39",
        "countyCode": "CAN",
        "countyName": "Canada (CAN, Americas)"
    },
    {
        "countyId": "40",
        "countyCode": "CPV",
        "countyName": "Cape Verde (CPV, Africa)"
    },
    {
        "countyId": "41",
        "countyCode": "CYM",
        "countyName": "Cayman Islands (CYM, Americas)"
    },
    {
        "countyId": "42",
        "countyCode": "CAF",
        "countyName": "Central African Republic (CAF, Africa)"
    },
    {
        "countyId": "43",
        "countyCode": "TCD",
        "countyName": "Chad (TCD, Africa)"
    },
    {
        "countyId": "44",
        "countyCode": "CHL",
        "countyName": "Chile (CHL, Americas)"
    },
    {
        "countyId": "45",
        "countyCode": "CHN",
        "countyName": "China (CHN, Asia)"
    },
    {
        "countyId": "46",
        "countyCode": "CXR",
        "countyName": "Christmas Island (CXR, Oceania)"
    },
    {
        "countyId": "47",
        "countyCode": "CCK",
        "countyName": "Cocos (Keeling) Islands (CCK, Oceania)"
    },
    {
        "countyId": "48",
        "countyCode": "COL",
        "countyName": "Colombia (COL, Americas)"
    },
    {
        "countyId": "49",
        "countyCode": "COM",
        "countyName": "Comoros (COM, Africa)"
    },
    {
        "countyId": "50",
        "countyCode": "COG",
        "countyName": "Congo (COG, Africa)"
    },
    {
        "countyId": "52",
        "countyCode": "COK",
        "countyName": "Cook Islands (COK, Oceania)"
    },
    {
        "countyId": "53",
        "countyCode": "CRI",
        "countyName": "Costa Rica (CRI, Americas)"
    },
    {
        "countyId": "54",
        "countyCode": "CIV",
        "countyName": "Cote D'Ivoire (Ivory Coast) (CIV, Africa)"
    },
    {
        "countyId": "55",
        "countyCode": "HRV",
        "countyName": "Croatia (HRV, Europe)"
    },
    {
        "countyId": "56",
        "countyCode": "CUB",
        "countyName": "Cuba (CUB, Americas)"
    },
    {
        "countyId": "249",
        "countyCode": "CUW",
        "countyName": "Curaçao (CUW, Americas)"
    },
    {
        "countyId": "57",
        "countyCode": "CYP",
        "countyName": "Cyprus (CYP, Europe)"
    },
    {
        "countyId": "58",
        "countyCode": "CZE",
        "countyName": "Czech Republic (CZE, Europe)"
    },
    {
        "countyId": "51",
        "countyCode": "COD",
        "countyName": "Democratic Republic of the Congo (COD, Africa)"
    },
    {
        "countyId": "59",
        "countyCode": "DNK",
        "countyName": "Denmark (DNK, Europe)"
    },
    {
        "countyId": "60",
        "countyCode": "DJI",
        "countyName": "Djibouti (DJI, Africa)"
    },
    {
        "countyId": "61",
        "countyCode": "DMA",
        "countyName": "Dominica (DMA, Americas)"
    },
    {
        "countyId": "62",
        "countyCode": "DOM",
        "countyName": "Dominican Republic (DOM, Americas)"
    },
    {
        "countyId": "63",
        "countyCode": "TLS",
        "countyName": "East Timor (TLS, Asia)"
    },
    {
        "countyId": "64",
        "countyCode": "ECU",
        "countyName": "Ecuador (ECU, Americas)"
    },
    {
        "countyId": "65",
        "countyCode": "EGY",
        "countyName": "Egypt (EGY, Africa)"
    },
    {
        "countyId": "66",
        "countyCode": "SLV",
        "countyName": "El Salvador (SLV, Americas)"
    },
    {
        "countyId": "67",
        "countyCode": "GNQ",
        "countyName": "Equatorial Guinea (GNQ, Africa)"
    },
    {
        "countyId": "68",
        "countyCode": "ERI",
        "countyName": "Eritrea (ERI, Africa)"
    },
    {
        "countyId": "69",
        "countyCode": "EST",
        "countyName": "Estonia (EST, Europe)"
    },
    {
        "countyId": "70",
        "countyCode": "ETH",
        "countyName": "Ethiopia (ETH, Africa)"
    },
    {
        "countyId": "71",
        "countyCode": "FLK",
        "countyName": "Falkland Islands (FLK, Americas)"
    },
    {
        "countyId": "72",
        "countyCode": "FRO",
        "countyName": "Faroe Islands (FRO, Europe)"
    },
    {
        "countyId": "73",
        "countyCode": "FJI",
        "countyName": "Fiji Islands (FJI, Oceania)"
    },
    {
        "countyId": "74",
        "countyCode": "FIN",
        "countyName": "Finland (FIN, Europe)"
    },
    {
        "countyId": "75",
        "countyCode": "FRA",
        "countyName": "France (FRA, Europe)"
    },
    {
        "countyId": "76",
        "countyCode": "GUF",
        "countyName": "French Guiana (GUF, Americas)"
    },
    {
        "countyId": "77",
        "countyCode": "PYF",
        "countyName": "French Polynesia (PYF, Oceania)"
    },
    {
        "countyId": "78",
        "countyCode": "ATF",
        "countyName": "French Southern Territories (ATF, Africa)"
    },
    {
        "countyId": "79",
        "countyCode": "GAB",
        "countyName": "Gabon (GAB, Africa)"
    },
    {
        "countyId": "80",
        "countyCode": "GMB",
        "countyName": "Gambia The (GMB, Africa)"
    },
    {
        "countyId": "81",
        "countyCode": "GEO",
        "countyName": "Georgia (GEO, Asia)"
    },
    {
        "countyId": "82",
        "countyCode": "DEU",
        "countyName": "Germany (DEU, Europe)"
    },
    {
        "countyId": "83",
        "countyCode": "GHA",
        "countyName": "Ghana (GHA, Africa)"
    },
    {
        "countyId": "84",
        "countyCode": "GIB",
        "countyName": "Gibraltar (GIB, Europe)"
    },
    {
        "countyId": "85",
        "countyCode": "GRC",
        "countyName": "Greece (GRC, Europe)"
    },
    {
        "countyId": "86",
        "countyCode": "GRL",
        "countyName": "Greenland (GRL, Americas)"
    },
    {
        "countyId": "87",
        "countyCode": "GRD",
        "countyName": "Grenada (GRD, Americas)"
    },
    {
        "countyId": "88",
        "countyCode": "GLP",
        "countyName": "Guadeloupe (GLP, Americas)"
    },
    {
        "countyId": "89",
        "countyCode": "GUM",
        "countyName": "Guam (GUM, Oceania)"
    },
    {
        "countyId": "90",
        "countyCode": "GTM",
        "countyName": "Guatemala (GTM, Americas)"
    },
    {
        "countyId": "91",
        "countyCode": "GGY",
        "countyName": "Guernsey and Alderney (GGY, Europe)"
    },
    {
        "countyId": "92",
        "countyCode": "GIN",
        "countyName": "Guinea (GIN, Africa)"
    },
    {
        "countyId": "93",
        "countyCode": "GNB",
        "countyName": "Guinea-Bissau (GNB, Africa)"
    },
    {
        "countyId": "94",
        "countyCode": "GUY",
        "countyName": "Guyana (GUY, Americas)"
    },
    {
        "countyId": "95",
        "countyCode": "HTI",
        "countyName": "Haiti (HTI, Americas)"
    },
    {
        "countyId": "96",
        "countyCode": "HMD",
        "countyName": "Heard Island and McDonald Islands (HMD, )"
    },
    {
        "countyId": "97",
        "countyCode": "HND",
        "countyName": "Honduras (HND, Americas)"
    },
    {
        "countyId": "98",
        "countyCode": "HKG",
        "countyName": "Hong Kong S.A.R. (HKG, Asia)"
    },
    {
        "countyId": "99",
        "countyCode": "HUN",
        "countyName": "Hungary (HUN, Europe)"
    },
    {
        "countyId": "100",
        "countyCode": "ISL",
        "countyName": "Iceland (ISL, Europe)"
    },
    {
        "countyId": "101",
        "countyCode": "IND",
        "countyName": "India (IND, Asia)"
    },
    {
        "countyId": "102",
        "countyCode": "IDN",
        "countyName": "Indonesia (IDN, Asia)"
    },
    {
        "countyId": "103",
        "countyCode": "IRN",
        "countyName": "Iran (IRN, Asia)"
    },
    {
        "countyId": "104",
        "countyCode": "IRQ",
        "countyName": "Iraq (IRQ, Asia)"
    },
    {
        "countyId": "105",
        "countyCode": "IRL",
        "countyName": "Ireland (IRL, Europe)"
    },
    {
        "countyId": "106",
        "countyCode": "ISR",
        "countyName": "Israel (ISR, Asia)"
    },
    {
        "countyId": "107",
        "countyCode": "ITA",
        "countyName": "Italy (ITA, Europe)"
    },
    {
        "countyId": "108",
        "countyCode": "JAM",
        "countyName": "Jamaica (JAM, Americas)"
    },
    {
        "countyId": "109",
        "countyCode": "JPN",
        "countyName": "Japan (JPN, Asia)"
    },
    {
        "countyId": "110",
        "countyCode": "JEY",
        "countyName": "Jersey (JEY, Europe)"
    },
    {
        "countyId": "111",
        "countyCode": "JOR",
        "countyName": "Jordan (JOR, Asia)"
    },
    {
        "countyId": "112",
        "countyCode": "KAZ",
        "countyName": "Kazakhstan (KAZ, Asia)"
    },
    {
        "countyId": "113",
        "countyCode": "KEN",
        "countyName": "Kenya (KEN, Africa)"
    },
    {
        "countyId": "114",
        "countyCode": "KIR",
        "countyName": "Kiribati (KIR, Oceania)"
    },
    {
        "countyId": "248",
        "countyCode": "XKX",
        "countyName": "Kosovo (XKX, Europe)"
    },
    {
        "countyId": "117",
        "countyCode": "KWT",
        "countyName": "Kuwait (KWT, Asia)"
    },
    {
        "countyId": "118",
        "countyCode": "KGZ",
        "countyName": "Kyrgyzstan (KGZ, Asia)"
    },
    {
        "countyId": "119",
        "countyCode": "LAO",
        "countyName": "Laos (LAO, Asia)"
    },
    {
        "countyId": "120",
        "countyCode": "LVA",
        "countyName": "Latvia (LVA, Europe)"
    },
    {
        "countyId": "121",
        "countyCode": "LBN",
        "countyName": "Lebanon (LBN, Asia)"
    },
    {
        "countyId": "122",
        "countyCode": "LSO",
        "countyName": "Lesotho (LSO, Africa)"
    },
    {
        "countyId": "123",
        "countyCode": "LBR",
        "countyName": "Liberia (LBR, Africa)"
    },
    {
        "countyId": "124",
        "countyCode": "LBY",
        "countyName": "Libya (LBY, Africa)"
    },
    {
        "countyId": "125",
        "countyCode": "LIE",
        "countyName": "Liechtenstein (LIE, Europe)"
    },
    {
        "countyId": "126",
        "countyCode": "LTU",
        "countyName": "Lithuania (LTU, Europe)"
    },
    {
        "countyId": "127",
        "countyCode": "LUX",
        "countyName": "Luxembourg (LUX, Europe)"
    },
    {
        "countyId": "128",
        "countyCode": "MAC",
        "countyName": "Macau S.A.R. (MAC, Asia)"
    },
    {
        "countyId": "130",
        "countyCode": "MDG",
        "countyName": "Madagascar (MDG, Africa)"
    },
    {
        "countyId": "131",
        "countyCode": "MWI",
        "countyName": "Malawi (MWI, Africa)"
    },
    {
        "countyId": "132",
        "countyCode": "MYS",
        "countyName": "Malaysia (MYS, Asia)"
    },
    {
        "countyId": "133",
        "countyCode": "MDV",
        "countyName": "Maldives (MDV, Asia)"
    },
    {
        "countyId": "134",
        "countyCode": "MLI",
        "countyName": "Mali (MLI, Africa)"
    },
    {
        "countyId": "135",
        "countyCode": "MLT",
        "countyName": "Malta (MLT, Europe)"
    },
    {
        "countyId": "136",
        "countyCode": "IMN",
        "countyName": "Man (Isle of) (IMN, Europe)"
    },
    {
        "countyId": "137",
        "countyCode": "MHL",
        "countyName": "Marshall Islands (MHL, Oceania)"
    },
    {
        "countyId": "138",
        "countyCode": "MTQ",
        "countyName": "Martinique (MTQ, Americas)"
    },
    {
        "countyId": "139",
        "countyCode": "MRT",
        "countyName": "Mauritania (MRT, Africa)"
    },
    {
        "countyId": "140",
        "countyCode": "MUS",
        "countyName": "Mauritius (MUS, Africa)"
    },
    {
        "countyId": "141",
        "countyCode": "MYT",
        "countyName": "Mayotte (MYT, Africa)"
    },
    {
        "countyId": "142",
        "countyCode": "MEX",
        "countyName": "Mexico (MEX, Americas)"
    },
    {
        "countyId": "143",
        "countyCode": "FSM",
        "countyName": "Micronesia (FSM, Oceania)"
    },
    {
        "countyId": "144",
        "countyCode": "MDA",
        "countyName": "Moldova (MDA, Europe)"
    },
    {
        "countyId": "145",
        "countyCode": "MCO",
        "countyName": "Monaco (MCO, Europe)"
    },
    {
        "countyId": "146",
        "countyCode": "MNG",
        "countyName": "Mongolia (MNG, Asia)"
    },
    {
        "countyId": "147",
        "countyCode": "MNE",
        "countyName": "Montenegro (MNE, Europe)"
    },
    {
        "countyId": "148",
        "countyCode": "MSR",
        "countyName": "Montserrat (MSR, Americas)"
    },
    {
        "countyId": "149",
        "countyCode": "MAR",
        "countyName": "Morocco (MAR, Africa)"
    },
    {
        "countyId": "150",
        "countyCode": "MOZ",
        "countyName": "Mozambique (MOZ, Africa)"
    },
    {
        "countyId": "151",
        "countyCode": "MMR",
        "countyName": "Myanmar (MMR, Asia)"
    },
    {
        "countyId": "152",
        "countyCode": "NAM",
        "countyName": "Namibia (NAM, Africa)"
    },
    {
        "countyId": "153",
        "countyCode": "NRU",
        "countyName": "Nauru (NRU, Oceania)"
    },
    {
        "countyId": "154",
        "countyCode": "NPL",
        "countyName": "Nepal (NPL, Asia)"
    },
    {
        "countyId": "156",
        "countyCode": "NLD",
        "countyName": "Netherlands (NLD, Europe)"
    },
    {
        "countyId": "157",
        "countyCode": "NCL",
        "countyName": "New Caledonia (NCL, Oceania)"
    },
    {
        "countyId": "158",
        "countyCode": "NZL",
        "countyName": "New Zealand (NZL, Oceania)"
    },
    {
        "countyId": "159",
        "countyCode": "NIC",
        "countyName": "Nicaragua (NIC, Americas)"
    },
    {
        "countyId": "160",
        "countyCode": "NER",
        "countyName": "Niger (NER, Africa)"
    },
    {
        "countyId": "161",
        "countyCode": "NGA",
        "countyName": "Nigeria (NGA, Africa)"
    },
    {
        "countyId": "162",
        "countyCode": "NIU",
        "countyName": "Niue (NIU, Oceania)"
    },
    {
        "countyId": "163",
        "countyCode": "NFK",
        "countyName": "Norfolk Island (NFK, Oceania)"
    },
    {
        "countyId": "115",
        "countyCode": "PRK",
        "countyName": "North Korea (PRK, Asia)"
    },
    {
        "countyId": "129",
        "countyCode": "MKD",
        "countyName": "North Macedonia (MKD, Europe)"
    },
    {
        "countyId": "164",
        "countyCode": "MNP",
        "countyName": "Northern Mariana Islands (MNP, Oceania)"
    },
    {
        "countyId": "165",
        "countyCode": "NOR",
        "countyName": "Norway (NOR, Europe)"
    },
    {
        "countyId": "166",
        "countyCode": "OMN",
        "countyName": "Oman (OMN, Asia)"
    },
    {
        "countyId": "167",
        "countyCode": "PAK",
        "countyName": "Pakistan (PAK, Asia)"
    },
    {
        "countyId": "168",
        "countyCode": "PLW",
        "countyName": "Palau (PLW, Oceania)"
    },
    {
        "countyId": "169",
        "countyCode": "PSE",
        "countyName": "Palestinian Territory Occupied (PSE, Asia)"
    },
    {
        "countyId": "170",
        "countyCode": "PAN",
        "countyName": "Panama (PAN, Americas)"
    },
    {
        "countyId": "171",
        "countyCode": "PNG",
        "countyName": "Papua new Guinea (PNG, Oceania)"
    },
    {
        "countyId": "172",
        "countyCode": "PRY",
        "countyName": "Paraguay (PRY, Americas)"
    },
    {
        "countyId": "173",
        "countyCode": "PER",
        "countyName": "Peru (PER, Americas)"
    },
    {
        "countyId": "174",
        "countyCode": "PHL",
        "countyName": "Philippines (PHL, Asia)"
    },
    {
        "countyId": "175",
        "countyCode": "PCN",
        "countyName": "Pitcairn Island (PCN, Oceania)"
    },
    {
        "countyId": "176",
        "countyCode": "POL",
        "countyName": "Poland (POL, Europe)"
    },
    {
        "countyId": "177",
        "countyCode": "PRT",
        "countyName": "Portugal (PRT, Europe)"
    },
    {
        "countyId": "178",
        "countyCode": "PRI",
        "countyName": "Puerto Rico (PRI, Americas)"
    },
    {
        "countyId": "179",
        "countyCode": "QAT",
        "countyName": "Qatar (QAT, Asia)"
    },
    {
        "countyId": "180",
        "countyCode": "REU",
        "countyName": "Reunion (REU, Africa)"
    },
    {
        "countyId": "181",
        "countyCode": "ROU",
        "countyName": "Romania (ROU, Europe)"
    },
    {
        "countyId": "182",
        "countyCode": "RUS",
        "countyName": "Russia (RUS, Europe)"
    },
    {
        "countyId": "183",
        "countyCode": "RWA",
        "countyName": "Rwanda (RWA, Africa)"
    },
    {
        "countyId": "184",
        "countyCode": "SHN",
        "countyName": "Saint Helena (SHN, Africa)"
    },
    {
        "countyId": "185",
        "countyCode": "KNA",
        "countyName": "Saint Kitts And Nevis (KNA, Americas)"
    },
    {
        "countyId": "186",
        "countyCode": "LCA",
        "countyName": "Saint Lucia (LCA, Americas)"
    },
    {
        "countyId": "187",
        "countyCode": "SPM",
        "countyName": "Saint Pierre and Miquelon (SPM, Americas)"
    },
    {
        "countyId": "188",
        "countyCode": "VCT",
        "countyName": "Saint Vincent And The Grenadines (VCT, Americas)"
    },
    {
        "countyId": "189",
        "countyCode": "BLM",
        "countyName": "Saint-Barthelemy (BLM, Americas)"
    },
    {
        "countyId": "190",
        "countyCode": "MAF",
        "countyName": "Saint-Martin (French part) (MAF, Americas)"
    },
    {
        "countyId": "191",
        "countyCode": "WSM",
        "countyName": "Samoa (WSM, Oceania)"
    },
    {
        "countyId": "192",
        "countyCode": "SMR",
        "countyName": "San Marino (SMR, Europe)"
    },
    {
        "countyId": "193",
        "countyCode": "STP",
        "countyName": "Sao Tome and Principe (STP, Africa)"
    },
    {
        "countyId": "194",
        "countyCode": "SAU",
        "countyName": "Saudi Arabia (SAU, Asia)"
    },
    {
        "countyId": "195",
        "countyCode": "SEN",
        "countyName": "Senegal (SEN, Africa)"
    },
    {
        "countyId": "196",
        "countyCode": "SRB",
        "countyName": "Serbia (SRB, Europe)"
    },
    {
        "countyId": "197",
        "countyCode": "SYC",
        "countyName": "Seychelles (SYC, Africa)"
    },
    {
        "countyId": "198",
        "countyCode": "SLE",
        "countyName": "Sierra Leone (SLE, Africa)"
    },
    {
        "countyId": "199",
        "countyCode": "SGP",
        "countyName": "Singapore (SGP, Asia)"
    },
    {
        "countyId": "250",
        "countyCode": "SXM",
        "countyName": "Sint Maarten (Dutch part) (SXM, Americas)"
    },
    {
        "countyId": "200",
        "countyCode": "SVK",
        "countyName": "Slovakia (SVK, Europe)"
    },
    {
        "countyId": "201",
        "countyCode": "SVN",
        "countyName": "Slovenia (SVN, Europe)"
    },
    {
        "countyId": "202",
        "countyCode": "SLB",
        "countyName": "Solomon Islands (SLB, Oceania)"
    },
    {
        "countyId": "203",
        "countyCode": "SOM",
        "countyName": "Somalia (SOM, Africa)"
    },
    {
        "countyId": "204",
        "countyCode": "ZAF",
        "countyName": "South Africa (ZAF, Africa)"
    },
    {
        "countyId": "205",
        "countyCode": "SGS",
        "countyName": "South Georgia (SGS, Americas)"
    },
    {
        "countyId": "116",
        "countyCode": "KOR",
        "countyName": "South Korea (KOR, Asia)"
    },
    {
        "countyId": "206",
        "countyCode": "SSD",
        "countyName": "South Sudan (SSD, Africa)"
    },
    {
        "countyId": "207",
        "countyCode": "ESP",
        "countyName": "Spain (ESP, Europe)"
    },
    {
        "countyId": "208",
        "countyCode": "LKA",
        "countyName": "Sri Lanka (LKA, Asia)"
    },
    {
        "countyId": "209",
        "countyCode": "SDN",
        "countyName": "Sudan (SDN, Africa)"
    },
    {
        "countyId": "210",
        "countyCode": "SUR",
        "countyName": "Suriname (SUR, Americas)"
    },
    {
        "countyId": "211",
        "countyCode": "SJM",
        "countyName": "Svalbard And Jan Mayen Islands (SJM, Europe)"
    },
    {
        "countyId": "212",
        "countyCode": "SWZ",
        "countyName": "Swaziland (SWZ, Africa)"
    },
    {
        "countyId": "213",
        "countyCode": "SWE",
        "countyName": "Sweden (SWE, Europe)"
    },
    {
        "countyId": "214",
        "countyCode": "CHE",
        "countyName": "Switzerland (CHE, Europe)"
    },
    {
        "countyId": "215",
        "countyCode": "SYR",
        "countyName": "Syria (SYR, Asia)"
    },
    {
        "countyId": "217",
        "countyCode": "TJK",
        "countyName": "Tajikistan (TJK, Asia)"
    },
    {
        "countyId": "218",
        "countyCode": "TZA",
        "countyName": "Tanzania (TZA, Africa)"
    },
    {
        "countyId": "219",
        "countyCode": "THA",
        "countyName": "Thailand (THA, Asia)"
    },
    {
        "countyId": "17",
        "countyCode": "BHS",
        "countyName": "The Bahamas (BHS, Americas)"
    },
    {
        "countyId": "220",
        "countyCode": "TGO",
        "countyName": "Togo (TGO, Africa)"
    },
    {
        "countyId": "221",
        "countyCode": "TKL",
        "countyName": "Tokelau (TKL, Oceania)"
    },
    {
        "countyId": "222",
        "countyCode": "TON",
        "countyName": "Tonga (TON, Oceania)"
    },
    {
        "countyId": "223",
        "countyCode": "TTO",
        "countyName": "Trinidad And Tobago (TTO, Americas)"
    },
    {
        "countyId": "224",
        "countyCode": "TUN",
        "countyName": "Tunisia (TUN, Africa)"
    },
    {
        "countyId": "225",
        "countyCode": "TUR",
        "countyName": "Turkey (TUR, Asia)"
    },
    {
        "countyId": "226",
        "countyCode": "TKM",
        "countyName": "Turkmenistan (TKM, Asia)"
    },
    {
        "countyId": "227",
        "countyCode": "TCA",
        "countyName": "Turks And Caicos Islands (TCA, Americas)"
    },
    {
        "countyId": "228",
        "countyCode": "TUV",
        "countyName": "Tuvalu (TUV, Oceania)"
    },
    {
        "countyId": "229",
        "countyCode": "UGA",
        "countyName": "Uganda (UGA, Africa)"
    },
    {
        "countyId": "230",
        "countyCode": "UKR",
        "countyName": "Ukraine (UKR, Europe)"
    },
    {
        "countyId": "231",
        "countyCode": "ARE",
        "countyName": "United Arab Emirates (ARE, Asia)"
    },
    {
        "countyId": "232",
        "countyCode": "GBR",
        "countyName": "United Kingdom (GBR, Europe)"
    },
    {
        "countyId": "234",
        "countyCode": "UMI",
        "countyName": "United States Minor Outlying Islands (UMI, Americas)"
    },
    {
        "countyId": "233",
        "countyCode": "USA",
        "countyName": "United States Of America (USA, Americas)"
    },
    {
        "countyId": "235",
        "countyCode": "URY",
        "countyName": "Uruguay (URY, Americas)"
    },
    {
        "countyId": "236",
        "countyCode": "UZB",
        "countyName": "Uzbekistan (UZB, Asia)"
    },
    {
        "countyId": "237",
        "countyCode": "VUT",
        "countyName": "Vanuatu (VUT, Oceania)"
    },
    {
        "countyId": "238",
        "countyCode": "VAT",
        "countyName": "Vatican City State (Holy See) (VAT, Europe)"
    },
    {
        "countyId": "239",
        "countyCode": "VEN",
        "countyName": "Venezuela (VEN, Americas)"
    },
    {
        "countyId": "240",
        "countyCode": "VNM",
        "countyName": "Vietnam (VNM, Asia)"
    },
    {
        "countyId": "241",
        "countyCode": "VGB",
        "countyName": "Virgin Islands (British) (VGB, Americas)"
    },
    {
        "countyId": "242",
        "countyCode": "VIR",
        "countyName": "Virgin Islands (US) (VIR, Americas)"
    },
    {
        "countyId": "243",
        "countyCode": "WLF",
        "countyName": "Wallis And Futuna Islands (WLF, Oceania)"
    },
    {
        "countyId": "244",
        "countyCode": "ESH",
        "countyName": "Western Sahara (ESH, Africa)"
    },
    {
        "countyId": "245",
        "countyCode": "YEM",
        "countyName": "Yemen (YEM, Asia)"
    },
    {
        "countyId": "246",
        "countyCode": "ZMB",
        "countyName": "Zambia (ZMB, Africa)"
    },
    {
        "countyId": "247",
        "countyCode": "ZWE",
        "countyName": "Zimbabwe (ZWE, Africa)"
    }
]