
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import {  Spin, Select, DatePicker, Pagination, Form, Checkbox, Button, Table,Empty, } from 'antd';
import { useState, useEffect, useRef, useCallback, } from "react";
import { SearchOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import http from "@/util/http";
import * as _ from "lodash"
import toast from "react-hot-toast";
import { useSearchParams, useNavigate, } from 'react-router-dom';
import Head from "./../header"
import Foot from "./../footer"

import moduleCss from "./index.module.scss";
import arrow_png from "@/assets/img/ship/arrow.png";
import {getPortListApi, getPortToPortListApi,getPortToPortRouteApi,} from "@/util/ship"
import { useUserStore } from "@/store/storeUser";
import openModalLogin  from "@/pc/modal/ModalLogin"

const { RangePicker } = DatePicker;

const isValidJSON = (text) => {
    try {
        JSON.parse(text);
        return true;
    } catch (error) {
        return false;
    }
}
const goodsType = [
    {
        "label": "普货(General Cargo)",
        "value": 10205
    },
    {
        "label": "(General Chemicals)",
        "value": 10206
    },
    {
        "label": "危险品(Dangerous goods)",
        "value": 10207
    },
    {
        "label": "冷冻冷藏(Refrigerated)",
        "value": 10208
    },
    {
        "label": "特种柜及大件货(Special Equipment and Bulk Goods)",
        "value": 10209
    },
    {
        "label": "冷危(Refrigerated Dangerous Goods)",
        "value": 10210
    },
    {
        "label": "私人物品(Personal Effects)",
        "value": 10211
    },
    {
        "label": "贵重物品(Valuables)",
        "value": 10212
    },
    {
        "label": "超重超长货物(Overweight&Overlength Cargo)",
        "value": 17521
    },
    {
        "label": "挂衣(Hanging Clothes)",
        "value": 17522
    },
    {
        "label": "药品(Drugs)",
        "value": 17523
    },
    {
        "label": "易腐生鲜(Perishable And Fresh)",
        "value": 17524
    },
    {
        "label": "整车(Vehicle)",
        "value": 17525
    },
    {
        "label": "电子产品(Electronic Products)",
        "value": 17526
    }
]


let urls_ = {}
export default function Ship() {
    const [fetching, setFetching] = useState(false);
    const [fetchingEnd, setFetchingEnd] = useState(false);
    const [fetchingList, setFetchingList] = useState(false);
    const userInfo = useUserStore((state) => state.userInfo);
    const setUserInfo = useUserStore((state) => state.setUserInfo);
    
    const navigate = useNavigate();

    const [form] = Form.useForm();
    const [initialValues, setInitialValues] = useState({Cntr_Type: 10205})
    const [searchParams, setSearchParams] = useSearchParams();
    const [startPortOptions, setStartPortOptions] = useState([]);
    const [endPortOptions, setEndPortOptions] = useState([]);
    const [queryData, setQueryData] = useState({});
    const [pageInfo, setPageInfo] = useState({
        total: 0,
        pageNum: 1,
        pageSize: 20,
    })

    let [historyList, setHistorylist] = useState([])
    

    const onFinish = (values) => {
        if (!values.beginTime || values.beginTime.length == 0) {
            toast.error('time is required！')
            return
        }
        if (!values.Cntr_Type) {
            toast.error('Cntr Type is required！')
            return
        }
        if (!values.startPortCode) {
            toast.error('startPort is required！')
            return
        }
        if (!values.endPortCode) {
            toast.error('endPort is required！')
            return
        }
        setSearchParams({
            start: values.startPortCode,
            end: values.endPortCode,
            sname: urls_.sname,
            ename: urls_.ename,
            time: values.beginTime,
            type: values.Cntr_Type,
        })
        setPageInfo({
            ...pageInfo,
            pageNum: 1,
            pageSize: 20,
        })
        if (pageInfo.pageNum == 1) {
            setQueryData({})
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const fetchStartPort = (value, callback) => {
        setStartPortOptions([]);
        setFetching(true);
        http.get("/port/list", { portName: value }).then((res) => {
            setFetching(false);
            callback(res.data);
        });
    }
    const handleSearchStartPort = useCallback(_.debounce((newValue) => {
        fetchStartPort(newValue, setStartPortOptions);
    },
    500,
        {
            leading: false,
            trailing: true
        },
    ), [])
    const handleChangesStartPort = (newValue) => {
        // setStartPort(newValue);
        form.setFieldValue('startPortCode', newValue.value)
        urls_.sname = newValue.label
    };

    const fetchEndPort = (value, callback) => {
        setEndPortOptions([]);
        setFetchingEnd(true);
        http.get("/port/list", { portName: value }).then((res) => {
            setFetchingEnd(true);
            callback(res.data);
        });
     }
    const handleSearchEndPort = useCallback(_.debounce((newValue) => {
        fetchEndPort(newValue, setEndPortOptions);
    },
    500,
        {
            leading: false,
            trailing: true
        },
    ), [])
    const handleChangesEndPort = (newValue) => {
        form.setFieldValue('endPortCode', newValue.value)
        urls_.ename = newValue.label
    };

    const gotoInquiry = (row) => {
        let transitTime = parseInt(row.transitTime / 24)
        let starttime = row.startPortEtd ? dayjs(row.startPortEtd).format('MM-DD') : ''
        let endtime = row.startPortEtd ? dayjs(row.startPortEtd).add(transitTime, 'day').endOf('day').format('MM-DD') : ''
        
        if (!userInfo.userName) {
            openModalLogin().then(res=>{
                setUserInfo(value=>{
                value.userName = res.userName
                return value
                })
                let ps = `startport=${searchParams.get('sname')}&endport=${searchParams.get('ename')}&goodsType=${searchParams.get('type')}&spendtime=${transitTime}&starttime=${starttime}&endtime=${endtime}&ship=${row.shippingCompanyName}`
                navigate('/inquiry/CIF?'+ ps)
            })
        } else {
            let ps = `startport=${searchParams.get('sname')}&endport=${searchParams.get('ename')}&goodsType=${searchParams.get('type')}&spendtime=${transitTime}&starttime=${starttime}&endtime=${endtime}&ship=${row.shippingCompanyName}`
            navigate('/inquiry/CIF?'
                + ps)

        }
    }
    
    const columns = [
        {
            title: 'Carrier',
            width: 100,
            dataIndex: 'shippingCompanyName',
            key: 'shippingCompanyName',
            sorter: true,
            render: (_, row,) => {
                return (<div className='tcell'>
                    <div className='tcell-value bold'>{_}</div>
                    <div className='tcell-capacity'></div>
                    <div className='division'>
                        <div className='btns'>
                            <div className='btn1'>Guanranteed Rate</div>
                            <div className='btn2'>Transfer</div>
                        </div>
                        <div className='time'>Validity Period ：{dayjs(row.validityStart).format("YYYY-MM-DD")}～{dayjs(row.validityEnd).format("YYYY-MM-DD")}</div>
                        <div className='upload'>
                            <span>Unfold</span>
                            <img src={ arrow_png}></img>
                        </div>
                    </div>
                </div>)
        },
        },
        {
            title: 'Port of Loading',
            width: 120,
            dataIndex: 'startPortName',
            key: 'startPortName',
            render: (_, row,) => {
                return (<div className='tcell'>
                    <div className='tcell-value bold'>{_}</div>
                    <div className='tcell-capacity'></div>

                </div>)
            },
        },
        {
            title: `CY Cut-
            off/Departure`,
            width: 130,
            dataIndex: `cyCutOff`,
            key: 'cyCutOff',
            render: (_, row,) => {
                return (<div className='tcell'>
                    <div className='tcell-value bold'>
                    {
                        row.cyCutOff ?? '_'
                        }
                        
                        <span>/</span>
                        {
                        row.startPortEtd ? dayjs(row.startPortEtd).format('MM-DD') : '_'
                           
                        }
                    </div>
                    <div className='tcell-capacity'></div>
                </div>)
            },
        },
        {
            title: `Transit Time
            （day）`,
            width: 130,
            dataIndex: 'transitTime',
            key: 'transitTime',
            render: (_, row,) => {
                return (<div className='tcell'>
                    <div className='tcell-value bold'>{parseInt(_/24)} days/Direct service</div>
                    <div className='tcell-capacity'></div>

                </div>)
            },
        },
        {
            title: `20GP`,
            width: 100,
            dataIndex: 'price20gp',
            key: 'price20gp',
            sorter: true,
            render: (_, row,) => {
                return (<div className='tcell'>
                    <div className='tcell-value money'>{row.currencyTag + row.price20gp}</div>
                    <div className='tcell-capacity'></div>

                </div>)
            },

        },
        {
            title: `40GP`,
            width: 100,
            dataIndex: 'price40gp',
            key: 'price40gp',
            sorter: true,
            render: (_, row,) => {
                return (<div className='tcell'>
                    <div className='tcell-value money'>{row.currencyTag + row.price40gp}</div>
                    <div className='tcell-capacity'></div>

                </div>)
            },

        },
        {
            title: `40HQ`,
            width: 100,
            dataIndex: 'price40hq',
            key: 'price40hq',
            sorter: true,
            render: (_, row,) => {
                return (<div className='tcell'>
                    <div className='tcell-value money'>{row.currencyTag + row.price40hq}</div>
                    <div className='tcell-capacity'></div>

                </div>)
            },

        },
        // {
        //     title: `40NOR`,
        //     width: 100,
        //     dataIndex: 'c8',
        //     key: 'c8',
        //     sorter: true,
        //     render: (_, row,) => {
        //         return (<div className='tcell'>
        //             <div className='tcell-value money'>{row.c8}</div>
        //             <div className='tcell-capacity'></div>

        //         </div>)
        //     },

        // },
        {
            title: `More Info`,
            width: 180,
            dataIndex: 'info',
            key: 'info',
            render: (_, row,) => {
                return (<div className='tcell'>
                    <div className='tcell-value info'>{row.info}</div>
                    <div className='tcell-capacity'></div>

                </div>)
            },
        },
        {
            title: '',
            width: 200,
            dataIndex: 'action',
            key: 'action',
            render: (_, row,) => (<>
                <div>
                <Button className='btn-1' onClick={() => gotoInquiry(row)}>Inquiry</Button>
                {/* <Button className='btn-2'>View</Button> */}
                </div>
                <div className='tcell-capacity'></div>

                
            </>),
          },
    ]
    
    const onChangePage = useCallback((page, pageSize) => {
        setPageInfo((pageInfo) => ({
            ...pageInfo,
            pageNum: pageSize != pageInfo.pageSize ? 1 : page,
            pageSize: pageSize,
        }))
    }, [pageInfo])

    const [tableData, setTableData] = useState([])
    const disabledDate = (current, { from }) => {
        return current < dayjs().add(2, 'day').endOf('day');
    };
    
    const clickHistory = useCallback((item) => {
        let t = item
        setStartPortOptions([{portName: t.startPortName, portCode: t.startPortCode}])
        setEndPortOptions([{portName: t.endPortName, portCode: t.endPortCode}])
        form.setFieldValue('startPortCode', t.startPortCode)
        form.setFieldValue('endPortCode',t.endPortCode)
        form.setFieldValue('Cntr_Type', t.Cntr_Type)
        form.setFieldValue('beginTime', [
            dayjs().add(3, 'day'),
            dayjs().add(4, 'week')
        ])

        
        if(pageInfo.pageNum == 1) {
            setQueryData({})
        } else {
            setPageInfo({
                ...pageInfo,
                pageNum: 1,
            })
        }
    }, [pageInfo])

    const queryTable = useCallback(() => {
        let values = form.getFieldsValue()
        if (!values.beginTime|| values.beginTime.length == 0 || !values.startPortCode || !values.endPortCode) {
            toast.error('Time, port, and cargo type are all required fields!')
            return
        }
        setFetchingList(true)
        let beginTime = dayjs(values.beginTime[0]).format('YYYY-MM-DD'); 
        let endTime = dayjs(values.beginTime[1]).format('YYYY-MM-DD'); 
        let data = {
            ...values,
            beginTime,
            endTime,
            pageNum: pageInfo.pageNum,
            pageSize: pageInfo.pageSize,
        }
        getPortToPortListApi(data).then(r => {
            setFetchingList(false)
            let h_item = {
                ...data,
            }
            h_item.startPortName = urls_.sname
            h_item.endPortName = urls_.ename
            let _shiphistory = isValidJSON(localStorage._shiphistory) &&localStorage._shiphistory  ? JSON.parse(localStorage._shiphistory) : []
            historyList = _shiphistory
            if (!_shiphistory.find(e => e.startPortCode == h_item.startPortCode && e.endPortCode == h_item.endPortCode)) {
                historyList.unshift(h_item)
                if (historyList.length > 8) {
                    historyList = historyList.slice(0, 8)
                }
                setHistorylist([...historyList])
                localStorage._shiphistory = JSON.stringify(historyList)
            } else {
                setHistorylist([...historyList])
            }
            

            setPageInfo({
                ...pageInfo,
                total: r.data.total,
            })
            let rst = r.data.rows.map(item => {
                return {
                    ...item,
                    info: `Surcharge details
                    Mode of Transshipment`,
                }
            })
            setTableData(rst)
        }).catch(e => {
            setFetchingList(false)
            console.log(e);
        })
    },[pageInfo.pageNum, pageInfo.pageSize,historyList])

    
    
    useEffect(() => {
        form.resetFields()
        let param = {
            startPortCode: searchParams.get('start') ?? '',
            endPortCode: searchParams.get('end')??'',
            beginTime: searchParams.get('time')?? dayjs().add(3, 'day').format("YYYY-MM-DD"),
            endTime: searchParams.get('time') ?
            dayjs(dayjs(searchParams.get('time')).add(4, 'week')).format("YYYY-MM-DD")
                :
                dayjs(dayjs().add(4, 'week')).format("YYYY-MM-DD"),
        }
        if (searchParams.get('sname')) {
            param.startPortCode && form.setFieldValue('startPortCode', param.startPortCode)
            setStartPortOptions([{portCode: param.startPortCode, portName: searchParams.get('sname')}])
            urls_.sname = searchParams.get('sname')
        }
        if (searchParams.get('ename')) {
            setEndPortOptions([{portCode: param.endPortCode, portName: searchParams.get('ename')}])
            param.endPortCode && form.setFieldValue('endPortCode', param.endPortCode)
            urls_.ename = searchParams.get('ename')
        }
        if (searchParams.get('type')) {
            form.setFieldValue('Cntr_Type', parseInt(searchParams.get('type')))
        }
        form.setFieldValue('beginTime', [
            searchParams.get('time') ? dayjs(searchParams.get('time')) : dayjs().add(3, 'day'),
            searchParams.get('time') ? dayjs(searchParams.get('time')).add(4, 'week') : dayjs().add(4, 'week')
        ])
        if (isValidJSON(localStorage._shiphistory) && localStorage._shiphistory) {
            setHistorylist(JSON.parse(localStorage._shiphistory))
        }
    }, [])
    useEffect(() => {
        queryTable();
    }, [pageInfo.pageNum, pageInfo.pageSize, queryData])

    return (
        <>
            <Head />
            <div className={moduleCss['search-block']}>
                <Form
                name="ship"
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                    layout="inline"
                    className={moduleCss.form_ship}
                    initialValues={initialValues}
            >
                    <div className="block">
                        <div className='block_search'>
                            <div className='content-port'>
                                <span className='title'>Port of Loading</span>
                                <span className='title title_end'>Port of Destination</span>
                                <div className='title_down'>
                                    <Form.Item
                                                name="startPortCode"
                                                label=""
                                                rules={[
                                                {
                                                    required: false,
                                                },
                                                ]}
                                                className="item1"
                                            >
                                                <Select
                                            showSearch
                                            labelInValue
                                            filterOption={false}
                                                    placeholder="POL"
                                                    onSearch={handleSearchStartPort}
                                                    onChange={handleChangesStartPort}
                                            popupMatchSelectWidth={false}
                                            notFoundContent={fetching ? <Spin size="small" /> : startPortOptions.length ? null: <Empty />}
                                                    options={startPortOptions.map((d) => ({
                                                        value: d.portCode,
                                                        label: d.portName,
                                                    }))}
                                            
                                                    />
                                        </Form.Item>
                                        <span className='arrow'>→</span>
                                    <Form.Item
                                        name="endPortCode"
                                        label=""
                                        rules={[
                                        {
                                            required: false,
                                        },
                                        ]}
                                        className="item1"
                                    >
                                        <Select
                                            showSearch
                                            filterOption={false}
                                            placeholder="POD"
                                            labelInValue
                                            onSearch={handleSearchEndPort}
                                            onChange={handleChangesEndPort}
                                            notFoundContent={fetchingEnd ? <Spin size="small" /> : endPortOptions.length ? null: <Empty />}
                                            popupMatchSelectWidth={false}
                                            options={endPortOptions.map((d) => ({
                                                value: d.portCode,
                                                label: d.portName,
                                            }))}
                                            />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className='content-port content-port-2'>
                                <span className='title'>Cntr Type</span>
                                <Form.Item
                                name="Cntr_Type"
                                label=""
                                rules={[
                                {
                                    required: false,
                                },
                                    ]}
                                    className="form_item_type"
                            >
                                <Select
                                    placeholder="Cntr Type"
                                        options={goodsType}
                                        
                                    />
                            </Form.Item>
                            </div>
                            <div className='content-port'>
                                <span className='title'>ETD</span>
                                <Form.Item
                                    name="beginTime"
                                    label=""
                                    rules={[
                                    {
                                        required: false,
                                    },
                                        ]}
                                        className="form_item_time"
                                >
                                    <RangePicker format="YYYY-MM-DD"
                                        disabledDate={disabledDate}
                                        allowClear={ false}
                                    />
                                    </Form.Item>
                            </div>
                        
                            <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
                            Search
                            </Button>
                        </div>
                        
                        <div className='history'>
                            <span className='title'>Search History</span>
                            <span className='right'>
                                {historyList.map((item, index) => {
                                    return (<span key={index} onClick={() => clickHistory(item)}>
                                        {item.startPortName} - {item.endPortName}
                                    </span>)
                                })}
                                {/* <span>Guangzhou - Istanbul</span>
                                <span>Shekou - Istanbul</span>
                                <span>Shenzhen - Istanbul</span> */}
                            </span>
                        </div>
                    </div>
                    
                </Form>
                
            </div>
            <div className='data-table'>
                <Table
                    columns={columns}
                    dataSource={tableData}
                    loading={fetchingList}
                    pagination={{
                        position: ['bottomRight'],
                        showQuickJumper: true,
                        total: pageInfo.total,
                        pageSize: pageInfo.pageSize,
                        current: pageInfo.pageNum,
                        pageSizeOptions: [20, 30, 50],
                        showSizeChanger:true,
                        showTotal: (total) => `Total ${total} `,
                        onChange: onChangePage,
                      }}
                    
                />
            </div>
            <Foot/>
        </>
    )
}

