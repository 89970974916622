import cs from "classnames"
import moduleCss from "./index.module.scss";
import { useSearchParams } from "react-router-dom";

import { Modal, Button, Timeline, } from 'antd';

import arrowora_png from "@/assets/img/inquery/arrow.png"


export default function Part(props) {
    const [searchParams, setSearchParams] = useSearchParams();
    const startport = searchParams.get('startport') ?? 'SHEKOU (CN, CNSHK)'
    const endport = searchParams.get('endport') ?? `ISTANBUL (TR, TRIST)`
    const goodsType = searchParams.get('goodsType') ?? 10205
    const spendtime = searchParams.get('spendtime') ?? 18
    const starttime = searchParams.get('starttime') ?? '07-06'
    const endtime = searchParams.get('endtime') ?? '07-24'
    const ship = searchParams.get('ship') ?? 'ONE'

    const lineData = [
        {
            title: 'Trailer',
            left: { title: 'ZHAOQING', time: '6-25', },
            right: { title: 'FOSHAN', time: '06-26', },
            distance: '89.6',
        },
        {
            title: 'Barge: FOSHAN',
            left: { title: 'FOSHAN', time: '06-27', },
            right: { title: 'SHEKOU', time: '06-30', },
            distance: '146.2',
        },
        {
            title: `Shipping companies：${ship}`,
            left: { title: startport, time: starttime, },
            right: { title: endport, time: endtime, },
            distance: '3067',
        },
        {
            title: 'Trailer',
            left: { title: 'Istanbul', time: '08-15', },
            right: { title: 'Catalca', time: '08-16', },
            distance: '73.1',
        },
    ]
    const showModal = () => {
        props.setIsModalOpen(true);
    };

    const handleOk = () => {
        props.setIsModalOpen(false);
    };

    const handleCancel = () => {
        props.setIsModalOpen(false);
    };


    const detailCostData = [
        {title: 'Unit price', value: '$1000',},
        {title: 'Number', value: '1',},
        {title: 'Tax refund rate', value: '10%',},
        {title: 'Single piece cost', value: '10%',},
        {title: 'Customs clearance tax rate', value: '10%',},
        {title: 'Anti dumping tax rate', value: '10%',},
        {title: 'Overall cost', value: '$10,000',},
    ]
    return (<>
        <div className={cs(moduleCss.part, 'outpart')}>
                {lineData.map((e, i) => {
                    return (<div className={cs(moduleCss.partOne)} key={i}>
                    <div className={moduleCss.dotwrap}>
                            <div className={moduleCss.left}>
                                <div></div>
                            </div>
                            <div className={moduleCss.right}>{e.title}</div>
                    </div>
                    <div className={moduleCss.down}>
                        <div className={moduleCss.line}></div>
                        <div className={moduleCss.right}>
                            <div className="bold">
                                <div>{e.left.title}</div>
                                <div>{e.left.time}</div>
                            </div>
                            <div className="arrow">
                                <img src={ arrowora_png} />
                                <div>{e.distance}km</div>
                            </div>
                            <div className="bold">
                                <div>{e.right.title}</div>
                                <div>{e.right.time}</div>
                            </div>
                        </div>
                    </div> 
                </div>)
                })}
            </div>
    </>)
}